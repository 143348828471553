import 'jquery';
import 'moment';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

window.UIkit = UIkit;

import './bootstrap.min.js';
import './jquery.fancybox.pack.js';
import './owl.carousel.js';
import './jquery.barrating.min.js';
import './isotope.pkgd.min.js';
import './scrollspy.js';
import './bootstrap-datetimepicker.min.js';
import './jquery.form.js';
import './jquery.form-validator.min.js';
import './jquery.selectBox.js';
import './jquery.matchHeight.js';
import './imagesloaded.pkgd.min.js';
import './theme.js';